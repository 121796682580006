import style from './contact.module.scss'
import {ContainerViewBoard} from "../../components/containerViewBoard";
import man from "../../assets/man3.png"
import menina from "../../assets/menina.png"

/**
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
 <path fill="#f2cd33" fill-opacity="1"
 d="M0,160L60,176C120,192,240,224,360,213.3C480,203,600,149,720,106.7C840,64,960,32,1080,32C1200,32,1320,64,1380,80L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
 </svg>*/

const Contact = () => {
  return (
    <div className={style.root}>
      <div className={style.container}>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.left}`}>
            <img src={menina} className={style.menina} alt={''}/>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.container2}`}>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children}`}>
            <svg style={{marginTop: -5}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <defs>
                <linearGradient id="gradiente" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="rgba(242, 207, 48, 1)" stopOpacity="1"/>
                  <stop offset="100%" stopColor="rgba(213, 125, 37, 1)" stopOpacity="1"/>
                </linearGradient>
              </defs>
              <path fill="url(#gradiente)" fillOpacity="1"
                    d="M0,160L60,176C120,192,240,224,360,213.3C480,203,600,149,720,106.7C840,64,960,32,1080,32C1200,32,1320,64,1380,80L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
            </svg>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.end}`}>
            <img src={man} className={style.man} alt={''}/>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.leftCenter}`}>
            <div className={style.text}>
              <h1 style={{color: "white"}}>
                <strong>A AbLink</strong><br/> foi feita para você!
              </h1>
              <p style={{color: "#FCD103"}}>
                Entre em contato<br/> e conheça um mundo<br/> totalmente mais<br/> rápido
              </p>
              <div style={{display: "flex", justifyItems: "center", alignItems: "center", gap: '1rem'}}>
                <div className={style.circle}>
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
                <p style={{color: "white"}}>Somos a única<br/> que entrega 100%<br/> do contratado!</p>
              </div>
            </div>
          </div>
        </ContainerViewBoard>

      </div>
    </div>
  );
}

export default Contact;
