import style from './helpers.module.scss'
import {ContainerViewBoard} from "../../components/containerViewBoard";
import {useState} from "react";
import {MagicMotion} from "react-magic-motion";
import logo from '../../assets/ABLINK-BRANCO.png';

interface OpenState {
  [key: number]: boolean;
}

interface Faq {
  title: string;
  description: string;
}

const Helpers = () => {
  const [isOpen, setIsOpen] = useState<OpenState>({});

  const faqArray: Faq[] = [
    {
      title: 'O que é fibra óptica?',
      description: 'A fibra óptica é um meio de transmissão de dados que utiliza filamentos de vidro ou plástico para transmitir informações na forma de pulsos de luz, proporcionando alta velocidade e largura de banda em comparação com outros meios de comunicação. É amplamente utilizada em redes de comunicação para transmitir dados a longas distâncias de forma rápida e eficiente.'
    },
    {
      title: 'Por que a fibra é superior a outras tecnologias?',
      description: 'A fibra óptica é superior devido a sua alta velocidade, largura de banda ampla, imunidade a interferências, menor atenuação do sinal, maior segurança, menor peso e tamanho, durabilidade e compatibilidade com tecnologias futuras.'
    },
    {
      title: 'O que é Wi-Fi de alta performance?',
      description: 'Wi-Fi de alta performance refere-se a uma tecnologia sem fio que oferece velocidades de conexão rápidas e confiáveis, possibilitando uma experiência de internet mais eficiente e fluida. Essa categoria de Wi-Fi utiliza padrões avançados, frequências otimizadas e tecnologias como MIMO (Multiple Input Multiple Output) para proporcionar uma conectividade mais rápida e estável em ambientes diversos.'
    },
    {
      title: 'Minha internet está lenta, o que eu faço?',
      description: `1. *Verifique a Conexão:* Certifique-se de estar conectado à rede correta e que não há problemas com seu provedor de serviços de internet.
                    \n2. *Reinicie o Roteador/Modem:* Desligue e ligue novamente o roteador/modem para reiniciar a conexão.
                    \n3. *Verifique Dispositivos Conectados:* Certifique-se de que não há muitos dispositivos conectados simultaneamente, o que pode sobrecarregar a rede.
                    \n4. *Posicionamento do Roteador:* Coloque o roteador em uma localização central e sem obstruções para melhorar a cobertura. 
                    \nSe o problema persistir, entre em contato conosco para obter assistência.`},
    {
      title: 'O que é a velocidade de download?',
      description: 'Velocidade de download refere-se à taxa na qual os dados são transferidos da internet para o seu dispositivo. É medida em megabits por segundo (Mbps) e afeta a rapidez com que você pode baixar arquivos, assistir a vídeos ou realizar outras atividades online.'
    },
    {
      title: 'O que é a velocidade de upload?',
      description: 'Velocidade de upload refere-se à taxa na qual os dados são transferidos do seu dispositivo para a internet. Medida em megabits por segundo (Mbps), influencia a rapidez com que você pode enviar arquivos, fazer videochamadas ou realizar outras atividades que exigem o envio de dados.'
    },
  ]

  const toggleOpen = (index: number) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <div className={style.root}>
      <div className={style.container}>
        <ContainerViewBoard>
          <div className={`${style.children} ${style.container2}`}>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={style.acordeon}>
            <h2 style={{color: "#FCD103"}}>DÚVIDAS? VAMOS TE AJUDAR</h2>
            <div className={`${style.todoList}`}>
              {faqArray.map((faq: Faq, index: number) => (
                <MagicMotion
                  key={index}
                  transition={{type: "spring", stiffness: 180, damping: 20, mass: 1.1}}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(238, 238, 238)",
                      padding: '0.5rem',
                      borderRadius: 12,
                      margin: "1rem 0",
                      overflow: "hidden",
                    }}
                  >
                    <button
                      className={style.buttonAcordeon}
                      onClick={() => toggleOpen(index)}
                    >
                      {faq.title}
                    </button>
                    {isOpen[index] && (
                      <div
                        style={{
                          gap: "1rem",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "1rem",
                          padding: '0.5rem'
                        }}
                      >
                        <div>
                          {faq.description}
                        </div>
                      </div>
                    )}
                  </div>
                </MagicMotion>
              ))}
            </div>
            <div style={{display: "flex", gap: '1rem'}}>
              <div className={style.circle}><a href='https://instagram.com/ablinktelecom' target='_blank'><i className="fa-brands fa-instagram"></i></a></div>
              <div className={style.circle}><a href='https://wa.me/5583981164701'><i className="fa-brands fa-whatsapp"></i></a></div>
              <div><img style={{width: 100}} src={logo} alt={''}/></div>
            </div>
          </div>
        </ContainerViewBoard>

      </div>
    </div>
  );
}

export default Helpers;
