import React, {useEffect, useState} from 'react';

interface TypewriterProps {
  children: JSX.Element;
}

const Typewriter: React.FC<TypewriterProps> = ({children}) => {
  const [displayChildren, setDisplayChildren] = useState<JSX.Element | null>(null);

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex < children.props.children.length) {
        setDisplayChildren(React.cloneElement(children, {
          children: children.props.children.slice(0, currentIndex + 1)
        }));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [children]);

  return <>{displayChildren}</>;
};

export default Typewriter;
