import style from './home.module.scss'
import {ContainerViewBoard} from "../../components/containerViewBoard";
import woman from '../../assets/woman.png'
import top_rightAB from '../../assets/top_right.png'
import top_leftB from '../../assets/top_left.png'
import ricos from '../../assets/ricos_top.png'
import wifi from '../../assets/8-1-0.png'
import Typewriter from "../../helpers/Typewriter";
import wifi2 from '../../assets/5-3-1.png'

const Home = () => {
  return (
    <div className={style.root}>
      <div className={style.container}>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.topRight}`}>
            <img src={ricos} className={style.riscos} alt={''}/>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.right}`}>
            <img src={top_rightAB} className={style.img} alt={''}/>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.right}`}>
            <img src={wifi} className={style.img} alt={''}/>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.left}`}>
            <img src={top_leftB} className={style.img} alt={''}/>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.woman}`}>
            <img src={wifi2} className={style.wifi2} alt={''}/>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.woman}`}>
            <section className={style.welcomeSection}>
              <Typewriter>
                <h1 className={style.text}>Tenha uma conexão que vai além da internet</h1>
              </Typewriter>
            </section>
            <img src={woman} className={style.img} alt={''}/>
          </div>
        </ContainerViewBoard>
      </div>
      <div className={style.footer}>
        <div className={style.slider}>
          <div className={style.slideTrack}>

            {/** O primeiro */}

            <div className={style.slider}>
              <div style={{lineHeight: '1'}} className={style.slideTextPrimary}>
                <h1>
                  São muitos<br/>os benefícios!
                </h1>
              </div>
            </div>
            <div className={style.slide}>
              <div className={style.slideTextSecondary}>
                <i className={`fa-solid fa-globe ${style.icon}`}></i>
                <p style={{lineHeight: '1', width: 104}}>
                  Internet rápida<br/>e
                  confiável.
                </p>
              </div>
            </div>
            <div className={style.slide}>
              <div className={style.slideTextSecondary}>
                <i className={`fa-solid fa-phone-volume ${style.icon}`}></i>
                <p style={{lineHeight: '1'}} className='w-auto text-1xl break-words text-left'>
                  Suporte<br/>dedicado
                </p>
              </div>
            </div>
            <div className={style.slide}>
              <div className={style.slideTextSecondary}>
                <i className={`fa-solid fa-thumbs-up ${style.icon}`}></i>
                <p style={{lineHeight: '1', width: 300}}>
                  Compromisso com<br/>a satisfação do cliente
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
