import {JSX} from "react";
import style from './containerViewBoard.module.scss'

interface ContainerViewBoardProps {
  children: JSX.Element;
}

export function ContainerViewBoard({children}: ContainerViewBoardProps) {

  return (
    <div className={style.children}>
      {children}
    </div>
  )
}