import React from "react";
import style from './cardPlan.module.scss'

interface CardPlanProps {
  megas: number;
  download: number;
  upload: number;
  numDispositivos?: number;
  balance: number;
  onClick: any;
}

const CardPlan: React.FC<CardPlanProps> = ({
                                             megas,
                                             download,
                                             upload,
                                             numDispositivos,
                                             balance,
                                             onClick,
                                           }) => {
  return (
    <div
      className={style.root}>

      <h1 className={style.textPrimary}>
        {megas}<span className={style.textPrimarySymbol}>+</span><br/><span className='text-4xl '>mega</span>
      </h1>

      <div className={style.textSecondary}>
        <p>
          <i className="fa-solid fa-cloud-arrow-down"></i>
          <span className={style.textSecondarySpan}>
          Download {download} Mbps
        </span>
        </p>

        <p>
          <i className="fa-solid fa-cloud-arrow-up"></i>
          <span className={style.textSecondarySpan}>
          Upload {upload} Mbps
        </span>
        </p>

        <p>
          <i className="fa-solid fa-screwdriver-wrench"></i>
          <span className={style.textSecondarySpan}>
          Instalação Gratuita*
        </span>
        </p>

        <p>
          <i className="fa-solid fa-wifi"></i>
          <span className={style.textSecondarySpan}>
          Roteador incluso
        </span>
        </p>

        <p>
          <i className='fa-solid fa-ethernet'></i>
          <span className={style.textSecondarySpan}>
          PC Connect
        </span>
        </p>
      </div>

      <h1 className={style.textBalance}>
        R$ {balance.toFixed(2)}
      </h1>
      <a target='_blank' href={`https://api.whatsapp.com/send?phone=5583981164701&text=Ol%C3%A1,%20vim%20pelo%20site%20e%20me%20interessei%20pelo%20plano%20de%20${megas}%20Mega.`}>
      <button
        className={style.button}>
        Assine já
      </button></a>

    </div>
  );
};


export default CardPlan;