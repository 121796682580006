import style from './plans.module.scss'
import CardPlan from "../../components/cardPlans";
import roteador from "../../assets/9-3-1.png"
import {ContainerViewBoard} from "../../components/containerViewBoard";

const Plans = () => {
  const plans = [
    {
      megas: 100,
      download: 100,
      upload: 100,
      balance: 64.90,
    },
    {
      megas: 200,
      download: 200,
      upload: 200,
      balance: 74.90,
    },
    {
      megas: 300,
      download: 300,
      upload: 300,
      balance: 84.90,
    },
    {
      megas: 500,
      download: 500,
      upload: 500,
      balance: 99.90,
    }
  ];

  const handleClick = (event: Event) => {
    console.log(event)
  }

  return (
    <div className={style.root}>
      <div className={style.container}>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.bottomCenter}`}>
            <img src={roteador} className={style.roteador} alt={''}/>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.bottomCenterText}`}>
            <p style={{marginBottom: 30}}> ** PC Connect: serviço de cabeamento para o seu dispositivo em até 10 metros,
              e que esteja dentro do mesmo ambiente comercial ou residencial </p>
          </div>
        </ContainerViewBoard>

        <ContainerViewBoard>
          <div className={`${style.children} ${style.center}`}>
            <div style={{marginBottom: 50}} className={style.slider}>
              <div className={style.slideTrack}>
                {plans.map((item) => (
                  <div className={style.slider}>
                    <CardPlan
                      megas={item.megas}
                      download={item.download}
                      upload={item.upload}
                      balance={item.balance}
                      onClick={handleClick}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ContainerViewBoard>


      </div>
    </div>
  );
};

export default Plans;
