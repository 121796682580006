import styles from './tabs.module.scss';
import {MagicTabSelect} from 'react-magic-motion';

interface TabsProps {
  array: string[];
  onTabSelect: (index: number) => void;
  selectedTab: number;
  column?: boolean;
}

export function Tabs({array, onTabSelect, selectedTab, column}: TabsProps) {
  const tabsComponents = array.map((text, i) => {
    return (
      <button
        type="button"
        key={`tab-${text}`}
        onClick={() => onTabSelect(i)}
        className={styles.buttonNavbar}
      >
        {text}

        {selectedTab === i && (
          <div style={{position: 'relative', transform: 'translateY(3px)'}}>
            <MagicTabSelect id="underline" transition={{type: 'spring', bounce: 0.3}}>
              <div
                style={{
                  width: '100%',
                  height: '0.15rem',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '1rem'
                }}
              />
            </MagicTabSelect>
          </div>
        )}
      </button>
    );
  });

  return (
    <>
      {column ? (
        <div style={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>{tabsComponents}</div>
      ) : (
        <div style={{display: 'flex', gap: '0.5rem'}}>{tabsComponents}</div>
      )}
    </>
  );
}
