import React, {useEffect, useRef, useState} from 'react';
import Navbar from "./layout/navbar";
import Home from "./pages/home";
import Plans from "./pages/plans";
import Contact from "./pages/contact";
import Helpers from "./pages/helpers";

const App: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollRefs = useRef<(HTMLDivElement | null)[]>(Array.from({length: 4}, () => null));

  const handleTabSelect = (index: number) => {
    setSelectedItem(index);
    if (scrollRefs.current[index]) {
      scrollRefs.current[index]!.scrollIntoView({behavior: 'smooth'});
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (!scrollContainer) return;
      const scrollTop = scrollContainer.scrollTop;
      const sessionHeight = window.innerHeight;
      const index = Math.round(scrollTop / sessionHeight);
      setSelectedItem(index);
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      <Navbar selectedTab={selectedItem} onTabSelect={handleTabSelect}/>
      <div
        ref={scrollContainerRef}
        style={{scrollSnapType: 'y mandatory', overflowY: 'scroll', height: '100vh'}}
      >
        {Array.from({length: 4}, (_, index) => (
          <div
            key={index}
            style={{scrollSnapAlign: 'start'}}
            ref={(ref) => (scrollRefs.current[index] = ref)}
          >
            {index === 0 && <Home/>}
            {index === 1 && <Plans/>}
            {index === 2 && <Contact/>}
            {index === 3 && <Helpers/>}
          </div>
        ))}
      </div>
    </>
  );
}

export default App;
