import styles from './navbar.module.scss';
import {Tabs} from '../../components/tabs';
import React, {useState} from "react";
import logo from "../../assets/ABLINK-BRANCO.png"

const underlineTabs = ['INICIO', 'PLANOS', 'CONTATO', 'DUVIDAS'];

interface NavbarProps {
  selectedTab: number;
  onTabSelect: (index: number) => void;
}

const Navbar: React.FC<NavbarProps> = ({selectedTab, onTabSelect}) => {
  const [open, setOpen] = useState(false)
  const [contatoLink, setContatoLink] = useState('https://wa.me/5583981164701');


  const handleOpenMenu = () => {
    setOpen(!open);
  }

  const handleTabSelect = (index: number) => {
    if (index === underlineTabs.indexOf('CONTATO')) {
      window.open(contatoLink, '_blank');
    } else {
      onTabSelect(index);
    }
  };

  return (
    <div className={open ? styles.header2 : styles.header}>
      <img src={logo} alt={''}
           style={open ? {height: '10%', paddingTop: '2rem'} : {height: '50%', paddingLeft: '2rem'}}/>

      {open ? (
          <>
            <Tabs array={underlineTabs} onTabSelect={handleTabSelect} selectedTab={selectedTab} column={open}/>
          </>
        ) :
        (
          <div className={styles.headerRight}>
            <Tabs array={underlineTabs} onTabSelect={handleTabSelect} selectedTab={selectedTab}/>
            <a href='https://cliente.ablinktelecom.com.br/' target='_blank' rel="noreferrer">
              <button className={styles.buttonPlan}>Área do Cliente</button>
            </a>
          </div>
        )}

      <div className={styles.buttonMobile}>
        <a href='https://cliente.ablinktelecom.com.br/' target='_blank' rel="noreferrer">
          <button className={styles.buttonPlan}>Área do Cliente</button>
        </a>
      </div>

      <button className={styles.buttonMobile} onClick={handleOpenMenu}>
        {open ? (
            <div style={{
              display: "flex",
              gap: '1rem',
              backgroundColor: "#377eb6",
              padding: '0.5rem',
              marginLeft: '2rem'
            }}>
              <i className="fa-solid fa-x"></i><span>Fechar</span>
            </div>
          ) :
          <i className="fa-solid fa-bars"></i>}
      </button>
    </div>
  );
}

export default Navbar;
